/**
 * External dependencies
 */
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Container, Row, Col } from "react-bootstrap"

/**
 * Internal dependencies
 */
import Accordions from "../accordion/accordion"
import accordionsData from "./static/accordions"
import LinkIcon from "../link-icon/link-icon"
import TrustpilotReviews from "@components/trustpilot"
import "./styles/_index.scss"
import { graphql } from "gatsby"
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  // InstapaperShareButton,
  WhatsappShareButton
} from "react-share";
import Stack from 'react-bootstrap/Stack';
import ContentBlock from "../content/Content";
import { StarberryIcons } from "../../components/icons/index";
const AreaGuideInfo = ({Content,Title,
  Show_Share,
  Show_Review,
  Toggle_Component,
  Single_Cta_Button}) => {
    const shareurl = typeof window !== 'undefined' ? window.location.href : ''
    const trackerShare = (event, scl_share_url) => {
      var shareURL = typeof window !== 'undefined' ? window.location.href : ''
      if (scl_share_url) {
          window.open(scl_share_url, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600')
      }
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
          'event': 'Share - social icons',
          'formType': event + ' - ' + shareURL,
          'formId': 'Share',
          'formName': 'Share',
          'formLabel': 'Share'
      });
  }
  
    return(
  <div className="area-guide-info-wrapper bg-grey">
    <Container className="area-guide-info">
      <Row className="g-0">
        <Col lg>
        <ContentBlock Content={Title} />
        {Show_Review &&
        <TrustpilotReviews
        language="en"
        culture="US"
        theme="light"
        width="100%"
        height="150px"
        template="53aa8807dec7e10d38f59f32"
        business="592d87f90000ff0005a381bf"
        // username="balgoresproperty"
        data-text-color="#e0e0e0"
        textcolor={'#000'}
        stars="4,5"
    />}
          {/* <Row className="area-guide-info__trustpilot align-items-center g-0">
            <Col xs="auto" className="area-guide-info__trustpilot-image">
              <StaticImage src="../../images/trustpilot.png" alt="trustpilot" />
            </Col>

            <Col xs="auto" className="area-guide-info__trustpilot-rating">
              <b>4.8/5</b>
              <span> from 89 Reviews</span>
            </Col>
          </Row> */}
          {Show_Share &&
          <>
          <h6>Share</h6>
            <Stack direction="horizontal" className="social-share-btns">
                    <FacebookShareButton onClick={() => trackerShare('FacebookShareButton')} url={shareurl} className="my-share-button facebook-share">
                        <StarberryIcons iconName="facebookIcon" className="bi" />
                    </FacebookShareButton>
                    <TwitterShareButton onClick={() => trackerShare('TwitterShareButton')} url={shareurl} className="my-share-button twitter-share">
                        <StarberryIcons iconName="twitterIcon" className="bi" />
                    </TwitterShareButton>
                    <LinkedinShareButton onClick={() => trackerShare('LinkedinShareButton')} url={shareurl} className="my-share-button linkedin-share">
                        <StarberryIcons iconName="linkedInIcon" className="bi" />
                    </LinkedinShareButton>
                   
                </Stack>
                </>
  }
        </Col>
        <Col md className="area-guide-info__text p-spacing">
        <ContentBlock Content={Content} />
          {Single_Cta_Button?.Url?.Alias &&
          <LinkIcon className="d-md-none" href={Single_Cta_Button?.Url?.Alias} text={Single_Cta_Button?.Label} />
          }

          {/* <div className="border-bottom d-block d-md-none mt-4_0 mb-4_0"></div> */}
          {Toggle_Component?.length > 0 &&
          <div className="area-guide-info__accordions border-top">
            <Accordions>
              {Toggle_Component?.map((x, index) => (
                <Accordions.Item eventKey={index} title={x.Title} key={index}>
                  <ContentBlock Content={x.Content} />
                </Accordions.Item>
              ))}
            </Accordions>
          </div>
          }
        </Col>
      </Row>
    </Container>
  </div>
)
              }
export default AreaGuideInfo
export const aboutContentFragmentGuide = graphql`
    fragment AboutContentFragmentGuide on GLSTRAPI_ComponentComponentAboutContent {
        id
        Content
        Title
        Show_Share
        Show_Review
        Toggle_Component {
          Content
          Title
        }
        Single_Cta_Button {
          Label
          Link
          Url {
            Alias
          }
        }
    }
`