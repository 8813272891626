/**
 * External dependencies
 */
import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"

/**
 * Internal dependencies
 */
import useAreaGuideGallery from "./use-area-guide-gallery"
import { convertGatsbyImageNodes } from "../../utils/gatsby-images"
import VideoPlaceholder from "../video-placeholder/video-placeholder"
import "./styles/_index.scss"

const AreaGuideGallery = (props) => {
  const data = useAreaGuideGallery()
  const images = convertGatsbyImageNodes(data)

  return (
    <div className="area-guide-gallery-wrapper bg-white">
      <Container className="area-guide-gallery container-big">
        <Row>
          {props?.Gallery?.map((item, i) =>{
            let processedImages = JSON.stringify({})
            if (props?.imagetransforms?.Gallery_Image_Transforms) {
              processedImages = props.imagetransforms.Gallery_Image_Transforms
            }
            return(
              <Col xs={12} md={6} lg={4} className="area-guide-gallery__col">
              <VideoPlaceholder 

              image={item?.Image}
              video={i === 1 ? props?.Video_Url : null}
                    alt={item?.Image?.alternativeText?item?.Image?.alternativeText+"- Strakers Estate Agents":"Area Guide Gallery - Strakers Estate Agents"}
                    imagetransforms={processedImages}
                    id={props.id}
                    Pagename={props.Title}
                    imgname={"area-guide.Gallery_Image.gallery"}
              
              />
            </Col>
  
            )
          })}

          {/* <Col
            xs={12}
            md={6}
            lg={4}
            className="area-guide-gallery__col"
          >
            <GatsbyImage image={images[2]} alt="background image" />
          </Col>

          <Col xs={12} lg={4} className="">
            <GatsbyImage image={images[3]} alt="background image" />
          </Col> */}
        </Row>
      </Container>
    </div>
  )
}

export default AreaGuideGallery
