/**
 * External dependencies
 */
import React, { useMemo} from "react"
import { Container, Row, Col } from "react-bootstrap"
import classNames from "classnames"
import { Link } from "gatsby"
/**
 * Internal dependencies
 */
 import {
  OFFICE_PAGE_URL
} from "../../components/common/site/constants"
import auctions from "./static/auctions"
import {
  isIOS,
  isMobile
} from "react-device-detect";
import Carousel from "../carousel/carousel"
import CardAuction from "./card-auction"
import useNewsAuctions from "./use-news-auctions"
import { convertGatsbyImageNodes } from "../../utils/gatsby-images"
import { toCapitalize } from "@components/common/utils";
import Button from "../button/button"
import "./styles/_index.scss"
import { useStaticQuery, graphql } from "gatsby";
import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import LinkIcon from "../link-icon/link-icon"
const OtherNewsAuctions = (props) => {
  const RELATED_OFFICE = gql`
  query GetOffice($URL: String!) {
    offices(publicationState: LIVE
      where: {URL_ne: $URL, Publish: true}
      limit: 3) {
        URL
        id
        Office_Address
        Office_Email
        Office_Name
        Office_Telephone_Lettings
        Office_Telephone_Progression
        Office_Telephone_Sales
        imagetransforms
        Tail_Image {
          alternativeText
          url
        }
    }
  }
`
  // const images = convertGatsbyImageNodes(data)

  const {
    loading: relatedofficesloading,
    error: relatedofficeserror,
    data: relatedoffices,
  } = useQuery(RELATED_OFFICE, {
    variables: { URL: props.URL },
  })
  const propertiesCollection = useMemo(
    () =>
    relatedoffices?.offices?.map((x, index) => {
       
      let processedImages = JSON.stringify({})
      if (x.imagetransforms?.Tail_Image_Transforms) {
        processedImages = x.imagetransforms.Tail_Image_Transforms
      }
        return (
          <Col
          key={x.title}
          className={classNames("news-auctions__col", {
            // "d-none d-lg-block": index >= 2,
          })}
        >
          <CardAuction
                      imagetransforms={processedImages}
                      id={x.id}
          
            image={x.Tail_Image}
            title={toCapitalize(x.Office_Name)}
            description={x.Office_Address}
            sales={x.Office_Telephone_Sales}
            lettings={x.Office_Telephone_Lettings}
            progression={x.Office_Telephone_Progression}
            href={OFFICE_PAGE_URL.alias+'/'+x.URL+'/'}
            agent={x}
            index={index}
            imgname={"office.Tail_Image.list"}
          />
        </Col>
        )
      }),
    [relatedoffices?.offices]
  )
  return (
    relatedoffices?.offices?.length > 0 ?
    <div className="home-news office pt-48 pb-48 pt-120 pb-120">
        <Container>
        <h6 className="home-news__heading fw-bolder fz14">{props.Cap_Title}</h6>
        <Row className="home-news__head-row g-0 align-items-md-center align-items-lg-start">
          <Col>
          <h3>{props.Title}</h3>
          </Col>

          <Col xs={12} md="auto">
          <Link             className="link-icon blue-link-hover d-flex align-items-center"
                            
                            to={OFFICE_PAGE_URL.alias}
                          >
                             <div
      className={`link-icon__text fw-bolder fz14 position-relative order-0`}
    >
      View Our Offices
    </div>
    <i className={classNames(`icon icon--arrow-up-right`)}></i>
                          </Link>
          </Col>
        </Row>
        </Container>
    <Container>
      <Row className="news-auctions__row flex-column flex-md-row">
        {relatedoffices?.offices?.length > 0 &&
                <Carousel
                className="news-land__row branches flex-column flex-md-row"
                data={propertiesCollection}
                controllers={{ buttons: isMobile && relatedoffices?.offices?.length > 1 ? true : !isMobile && relatedoffices?.offices?.length > 3 ? true : false }}
                isFluidContainer={false}
              />
        }
          
      </Row>


    </Container>
    </div>
  : null)
}

export default OtherNewsAuctions
