/**
 * External dependencies
 */
import React, {useState} from "react"
import { Link } from "gatsby"
import { Row, Col } from "react-bootstrap"
import Modal from 'react-bootstrap/Modal';
/**
 * Internal dependencies
 */
import Card from "../card/card"
import LinkIcon from "../link-icon/link-icon"
import classNames from "classnames"
import ContactForm from "../../blocks/contact-form/contact-form"
const CardAuction = ({
  imgname, imagetransforms, id,
  image,
  description,
  title,
  sales,
  lettings,
  progression,
  href,
  index
}) => {
  const [showForm, setShowForm] = useState(false);
  const [indexI, setIndex] = useState(null);
  const openform = (i) => {
    if (indexI === i) {
      setIndex(null)
      setShowForm(false)
    }
    else {
      setIndex(i)
      setShowForm(true)
    }
  }
  return(
  <Card className="card-auction">
    {/* <Link to={href}> */}
      <Card.Head href={href} onImageHoverZoom image={image} imgname={imgname} imagetransforms={imagetransforms} id={id} alt={title+" - Strakers Estate Agents"} />
    {/* </Link> */}
    <Card.Body>
      <h5>
        <Link to={href}>{title}</Link>
      </h5>
      <p>{description}</p>

      {sales && (
        <div className="card-auction__detail">
          <span className="fw-bolder">Sales: </span>
          <a href={`tel:${sales}`} className="black-link-hover card-auction__value">{sales}</a>
        </div>
      )}

      {lettings && (
        <div className="card-auction__detail">
          <span className="fw-bolder">Lettings: </span>
            <a href={`tel:${lettings}`} className="black-link-hover card-auction__value">{lettings}</a>
        </div>
      )}

      {progression && (
        <div className="card-auction__detail">
          <span className="fw-bolder">Sales Progression: </span>
          <a href={`tel:${progression}`} className="black-link-hover card-auction__value">{progression}</a>
        </div>
      )}
    </Card.Body>

    <Card.Footer>
      <Row className="g-0 flex-nowrap justify-content-between">
        <Col xs="auto">
        <Link
              class="link-icon blue-link-hover d-flex align-items-center"

              to={href}
            >
              <div
                className={`link-icon__text fw-bolder fz14 position-relative order-0`}
              >
                Branch Details
              </div>
              <i className={classNames(`icon icon--eye`)}></i>
            </Link>
          {/* <LinkIcon href={href} text="Branch Details" icon="eye" /> */}
        </Col>

        <Col xs="auto">
          <Card.Delimiter />
        </Col>

        <Col xs="auto">
        <a href='javascript:void(0)' onClick={() => openform(index)} className="link-icon blue-link-hover d-flex align-items-center"

>
  <div
    className={`link-icon__text fw-bolder fz14 position-relative order-0`}
  >
    Email
  </div>
  <i className={classNames(`icon icon--mail`)}></i>
</a>
          {/* <LinkIcon href={href} text="Email" icon="mail" /> */}
          <Modal className="officedetails popupForm" backdrop="static" size="lg" show={showForm && index === indexI} onHide={() => { setShowForm(false); setIndex(null) }}>
            <Modal.Header closeButton>
              <Modal.Title> <div className="form__head">
                <h4>Contact {title}</h4>
              </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ContactForm branch={title} popup={true} />
            </Modal.Body>

          </Modal>
        </Col>
      </Row>
    </Card.Footer>
  </Card>
)
      }
export default CardAuction
