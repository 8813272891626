const accordions = [
  {
    title: 'Restaurants',   
    answer: 'Lorem ipsum dolar am sem lpsa'
  },
  {
    title: 'Family Days Out',   
    answer: 'Lorem ipsum dolar am sem lpsa'
  },
  {
    title: 'Schools',   
    answer: 'Lorem ipsum dolar am sem lpsa'
  },
  {
    title: 'Walking the Dog',   
    answer: 'Lorem ipsum dolar am sem lpsa'
  },
  {
    title: 'Golf Courses',   
    answer: 'Lorem ipsum dolar am sem lpsa'
  },
  {
    title: 'Parks',   
    answer: 'Lorem ipsum dolar am sem lpsa'
  },
  {
    title: 'Coffee',   
    answer: 'Lorem ipsum dolar am sem lpsa'
  },
  {
    title: 'Our Favourite Bit',   
    answer: 'Lorem ipsum dolar am sem lpsa'
  }
];

export default accordions