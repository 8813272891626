/**
 * External dependencies
 */
import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
/**
 * Internal dependencies
 */
import ContactForm from "../../blocks/contact-form/contact-form"
import BasicContactForm from "../../blocks/contact-form/contact-form-basic"
import ContentBlock from "../content/Content";
import "./styles/_index.scss"
import ImageTransform from "../../components/common/ggfx-client/module/components/image-transform"
const HomeContact = ({Choose_Block, branch, Email, Hide_Fields}) => {
  let processedImages = JSON.stringify({});
  if (Choose_Block?.imagetransforms?.Image_Transforms) {
    processedImages = Choose_Block?.imagetransforms?.Image_Transforms;
  }
  let processedImages1 = JSON.stringify({});
  if (Choose_Block?.imagetransforms?.Sign_Image_Transforms) {
    processedImages1 = Choose_Block?.imagetransforms?.Sign_Image_Transforms;
  }
  return(

  <div className="home-contact pt-48 pb-48 pt-120 pb-120">
    <Container>
      <Row className="justify-content-lg-between align-items-lg-center">
        <Col xs={12} lg="auto" className="home-contact__head">
          <h2 className="h1-like">{Choose_Block?.Title}</h2>

          <div className="p-spacing">
          <ContentBlock Content={Choose_Block?.Content} />
          </div>

          <Row className="home-contact__person-block g-0">
            <Col className="home-contact__person-image" xs="auto">
            
            <ImageTransform
                                    imagesources={Choose_Block?.Image?.url}
                                    renderer={"srcSet"}
                                    imagename={"get-started.Image.small"}
                                    attr={{
                                      alt: Choose_Block?.Image?.alternativeText
                                        ? Choose_Block?.Image?.alternativeText
                                        : "Home contact Strakers",
                                      className: "",
                                    }}
                                    imagetransformresult={processedImages}
                                    id={Choose_Block?.id}
                                  ></ImageTransform>
           
            </Col>

            <Col>
              <div className="home-contact__signature">
              {/* <ImageTransform
                                    imagesources={Choose_Block?.Sign_Image?.url}
                                    renderer={"srcSet"}
                                    imagename={"get-started.Sign_Image.small"}
                                    attr={{
                                      alt: Choose_Block?.Sign_Image?.alternativeText
                                        ? Choose_Block?.Sign_Image?.alternativeText
                                        : "Home contact Strakers",
                                      className: "",
                                    }}
                                    imagetransformresult={processedImages1}
                                    id={Choose_Block?.id}
                                  ></ImageTransform> */}
                <img
                  src={Choose_Block?.Sign_Image?.url}
                  alt={Choose_Block?.Sign_Image?.alternativeText}
                />
              </div>
              <div className="home-contact__person-name fw-bolder">
                {Choose_Block?.Name}
              </div>
              <span className="home-contact__person-position">
                {Choose_Block?.Designation}
              </span>
            </Col>
          </Row>
        </Col>

        <Col lg="auto" className="home-contact__form-col">
          {Hide_Fields ?
          <BasicContactForm isHome={true} Email={Email} />
          :
          <ContactForm isHome={true} branch={branch} />
          }
        </Col>
      </Row>
    </Container>
  </div>
)
        }
export default HomeContact

export const getStartedFragment = graphql`
    fragment GetStartedFragment on GLSTRAPI_ComponentComponentGetStarted {
         Choose_Block {
          Content
          Designation
          Name
          Publish
          Show_Form
          Title
          id
        imagetransforms
       
          Image {
            alternativeText
            url
          }
          Sign_Image {
            alternativeText
            url
          }
        }
        Email
        Hide_Fields
    }
`