/**
 * External dependencies.
 */
import React from "react"
import { Col, Row } from "react-bootstrap"

/**
 * Internal dependencies.
 */
import Button from "../button/button"
import IntroContent from "../intro-content/intro-content"
// import useAreaGuideIntro from "./use-area-guide-intro"
import VideoPlaceholder from "../video-placeholder/video-placeholder"
import { convertGatsbyImageNodes } from "../../utils/gatsby-images"
import "./styles/_index.scss"
import ContentBlock from "../content/Content";
const AreaGuideIntro = (props) => {
  // const data = useAreaGuideIntro()
  // const images = convertGatsbyImageNodes(data)

  return (
    <IntroContent fluidContainer={false} 
    image={props.Banner_Image}
    video={props.Banner_Video}
          alt={props.Banner_Image?.alternativeText}
          imagetransforms={props.imagetransforms}
          id={props.id}
          imgname={props.imgname}
          Pagename={props.Pagename} className="area-guide-intro area-guide">
      <div className="intro-content__content">
      <h1 className="mb-1_6">{props.Banner_Title}</h1>
        <ContentBlock Content={props.Banner_Content} />
        {props?.Cta_Links?.length > 0 &&
        <Row className="intro-content__cta">
          {props?.Cta_Links?.map((item, i) => {
            return(
              <Col xs="12" md="6" className={i === 0 ? "mb-4 pe-md-3 mb-md-0" : "mb-4 ps-md-4 mb-md-0"}>
              <Button link={item?.Link} href={item?.Url?.Alias} color={i === 0 ? "yellow" : "blue"}>
                {item?.Icon_Name &&
                <Button.Icon>
                  <i className={`icon icon--${item?.Icon_Name}`} />
                </Button.Icon>
                }
                <Button.Text>{item?.Label}</Button.Text>
              </Button>
            </Col>  
            )
          })}
        </Row>
        }
        {/* <div className="opacity-60">
          <button className="clear-btn jump-icon-hover">
            <Row className="intro-content__scroll align-items-center">
              <Col xs="auto" className="pe-0 me-0_8">
                <i className="icon icon--scroll"></i>
              </Col>
              <Col className="ps-0">
                <p className="body-xs mb-0 d-block d-md-none">
                  Or scroll for more information
                </p>
                <p className="body-xs mb-0 d-none d-md-block d-lg-none">
                  Or scroll for more about our property management service
                </p>
                <p className="body-xs mb-0 d-none d-lg-block">
                  Or scroll for more information about Devizes
                </p>
              </Col>
            </Row>
          </button>
        </div> */}
      </div>

      {/* <div className="area-guide-intro__video d-none d-xl-block position-absolute">
        <VideoPlaceholder
          image={images["video-image"]}
          title="Introducing Devizes"
          btnIcon="calendar"
          btnText="Book an appointment"
          playBtnOnRight={true}
          alt="video"
        />
      </div> */}
    </IntroContent>
  )
}

export default AreaGuideIntro
