import React, { useEffect, useState, useRef } from "react"
import { Helmet } from "react-helmet"
import Seo from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs"
import Layout from "../components/layout"
import logoBlack from "../images/svg/logo.svg"
import NotFoundPage from "../pages/404"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { useStaticQuery, graphql } from "gatsby"
import AreaGuideIntro from "../components/area-guide-intro/area-guide-intro"
import AreaGuideInfo from "../components/area-guide-info/area-guide-info"
import AreaGuideGallery from "../components/area-guide-gallery/area-guide-gallery"
import HomeBanner from "../components/home-banner/home-banner"
import LocRatingMap from '../components/map/loc-rating-map';
import { MapMarkerInfoBox } from '../components/map/map-marker';
import HomeContact from "../components/home-contact/home-contact"
import {
  AboutContentFragmentGuideGql,
  BannerCtaBlockFragmentGql,
  GetStartedFragmentGql,
} from "../components/gql"
import OtherNewsAuctions from "../components/news-auctions/other-news-auctions"
import BranchProperties from "../components/home-properties/branch-properties"
const AreaGuidesDetail = (props) => {

  const GET_AREA_DETAILS = gql`
  query GetAreaGuidesDetail($URL: String!) {
    areaGuides(where: { URL: $URL }) {
      Title
      Tile_Image {
        alternativeText
        url
      }
      Video_Url
      URL
      Banner_Content
      Banner_Image {
        alternativeText
        url
      }
      Banner_Title
      Gallery {
        Image {
          alternativeText
          url
        }
      }
      Latitude
      Longitude
      Meta_Description
      Meta_Title
      Publish
      imagetransforms
      id
      Modules {
        ... on ComponentComponentAboutContent {
          id
          __typename
          ...AboutContentFragmentGuideGql
        }
        ... on ComponentComponentBannerCtaBlock {
          id
          __typename
          ...BannerCtaBlockFragmentGql
        }
        ... on ComponentComponentGetStarted {
          id
          __typename
          ...GetStartedFragmentGql
        }
      }
     
    }
  }
  ${AboutContentFragmentGuideGql}
  ${BannerCtaBlockFragmentGql}
  ${GetStartedFragmentGql}
`

  const { loading, error, data } = useQuery(GET_AREA_DETAILS, {
    variables: { URL: props.slug },
    fetchPolicy: "no-cache"
  })
  let shareurl = ""
  if (typeof window !== "undefined") {
    shareurl = window.location.href ? window.location.href : ""
  }
  let mapItems = [];
  let mapItem = {};
  mapItem['lat']  = data?.areaGuides[0]?.Latitude      
  mapItem['lng']  = data?.areaGuides[0]?.Longitude
  const mapMarkerImageShowFlag = 0;
  let mapItemHtml = MapMarkerInfoBox("test", mapMarkerImageShowFlag);
  mapItem['html'] = mapItemHtml;
  mapItems.push( mapItem );

  if (loading)
    return (
      <div className="static-news gql-loading-screen">
        <Container>
          <div className="gql-loading">
            <img
              className="logo-white loader-logo"
              src={logoBlack}
              alt="logo"
            />
          </div>
        </Container>
      </div>
    )

  return data?.areaGuides?.length > 0 ? (
    <Layout
    isTspPage={true}
    searchtype={"office"}
    area={data?.areaGuides[0]?.Title}
    >
      {data?.areaGuides?.map((Page, i) => {
        let processedImages = JSON.stringify({})
        if (Page?.imagetransforms?.Banner_Image_Transforms) {
          processedImages = Page.imagetransforms.Banner_Image_Transforms
        }
        const path = {
          pathname: `/about/${props?.alias}/${props?.slug}/`
        }
        return (
          <>
            <Seo
              title={
                Page ? Page?.Title + " Area Guide" : ""
              }
              location={path}
              description={`Know more about ${Page?.Title} here. Contact one of our estate agents for assistance in finding the right properties for you in ${Page?.Title}.`}
              image={Page?.Banner_Image?.url ? Page.Banner_Image.url : null}
            />
            <Helmet
              bodyAttributes={{
                class: `templates-area-details-template area-page`,
              }}
            />
            <AreaGuideIntro
              Pagename={Page?.Title}
              Alias={Page?.URL}
              Banner_Image={Page?.Banner_Image}
              // Banner_Video={Page?.Banner_Video}
              imagetransforms={processedImages}
              id={Page?.id}
              Banner_Content={Page?.Banner_Content}
              Cta_Links={[
                {
                  "Label": "Book a Valuation",
                  "Icon_Name": "house-line",
                  "Url": {
                    "Alias": "property-valuation-in-wiltshire",
                  }
                },
                {
                  "Label": "Find a Home",
                  "Icon_Name": "search",
                  "Link": "/property/for-sale/in-"+data?.areaGuides[0]?.Title?.replace(/ /g, "-")?.toLowerCase(),
                  
                }
              ]}
              Banner_Title={Page?.Banner_Title}
              imgname={"area-guide.Banner_Image.bannerimg"}
            />
            <Breadcrumbs Page={Page?.Title} alias={"area-guide"} type={"details-page"} />
            {Page?.Modules && Page?.Modules?.length > 0
              ? Page?.Modules?.map((Module, i) => {
                return (
                  <div key={i}>
                    {Module.__typename ===
                      "ComponentComponentAboutContent" && (
                        <AreaGuideInfo Pagename={Page?.Title} Alias={Page?.URL} {...Module} />
                      )}

                    {i === 0 && Page?.Gallery?.length >= 3 &&
                    <>
                      <AreaGuideGallery Video_Url={Page?.Video_Url} imagetransforms={Page?.imagetransforms} id={Page?.id} Gallery={Page?.Gallery} />
                      <div className="area-guide-map">
                      <LocRatingMap data={mapItems} />
                      </div>
                      <BranchProperties area={Page?.Title}
                      Title={`Recently listed properties`}
                      Cap_Title={"Be inspired"}
                      />
                      <div className="border-bottom"></div>
                      <OtherNewsAuctions Cap_Title={"Our Branches"} Title={"Expore Our Other Offices"} URL={Page?.URL} />
                      </>
                    }
                    {Module.__typename ===
                      "ComponentComponentBannerCtaBlock" && (
                        <HomeBanner
                          Pagename={Page?.Title} Alias={Page?.URL} {...Module} />
                      )}

                    {Module.__typename ===
                      "ComponentComponentGetStarted" && (
                        <HomeContact
                        branch={Page?.Title} Alias={Page?.URL} {...Module} />
                      )}

                  </div>
                )
              })
              : ""}



          </>
        )
      })}
    </Layout>
  ) : (
    <Layout className="blog-page">
      <Router>
        <Switch>
          <Route component={NotFoundPage} />
        </Switch>
      </Router>
    </Layout>
  )

}

export default AreaGuidesDetail