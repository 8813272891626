import gql from 'graphql-tag';

export const AboutContentFragmentGuideGql = gql`
    fragment AboutContentFragmentGuideGql on ComponentComponentAboutContent {
        id
        __typename
        Content
        Title
        Show_Share
        Show_Review
        Toggle_Component {
          Content
          Title
        }
        Single_Cta_Button {
          Label
          Link
          Url {
            Alias
          }
        }
    }
`


export const BannerCtaBlockFragmentGql = gql`
    fragment BannerCtaBlockFragmentGql on ComponentComponentBannerCtaBlock {
        id
        __typename
        Banner_block {
          id
          imagetransforms
          Big_Title
          Content
          Publish
          Mobile_Image {
            alternativeText
            url
          }
          Banner_Cta {
            Label
            Link
            Url {
              Alias
            }
          }
          Image {
            alternativeText
            url
          }
          Title
        }

    }
`


export const GetStartedFragmentGql = gql`
    fragment GetStartedFragmentGql on ComponentComponentGetStarted {
        id
        __typename
        Choose_Block {
          id
          imagetransforms
          Content
          Designation
          Name
          Publish
          Show_Form
          Title
          Image {
            alternativeText
            url
          }
          Sign_Image {
            alternativeText
            url
          }
        }
    }
`
